<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="''"
        :fill-color="color"
        @click="$emit('click')"
    >
        <path
            d="M12,24c-5.376,0-9.75-4.374-9.75-9.75c0-5.133,3.925-9.335,9-9.719V1.5h-1.5C9.336,1.5,9,1.164,9,0.75S9.336,0,9.75,0h4.5
		C14.664,0,15,0.336,15,0.75S14.664,1.5,14.25,1.5h-1.5v3.031c1.893,0.144,3.67,0.824,5.177,1.982l0.911-0.911
		C18.78,5.495,18.75,5.375,18.75,5.25c0-0.2,0.078-0.389,0.22-0.53S19.3,4.5,19.5,4.5s0.389,0.078,0.53,0.22l0.75,0.75
		C20.922,5.611,21,5.8,21,6s-0.078,0.389-0.22,0.53s-0.33,0.22-0.53,0.22c-0.125,0-0.245-0.03-0.352-0.087l-0.855,0.855
		c1.749,1.824,2.707,4.201,2.707,6.733C21.75,19.626,17.376,24,12,24z M12,6c-4.549,0-8.25,3.701-8.25,8.25S7.451,22.5,12,22.5
		s8.25-3.701,8.25-8.25S16.549,6,12,6z"
        />
        <path
            d="M12,15.75c-0.212,0-0.414-0.09-0.556-0.247l-3.75-4.151c-0.277-0.307-0.253-0.782,0.054-1.059
		c0.138-0.125,0.317-0.193,0.503-0.193c0.212,0,0.415,0.09,0.557,0.247l3.75,4.151c0.277,0.307,0.253,0.782-0.054,1.059
		C12.364,15.681,12.186,15.75,12,15.75z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'StopwatchIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
line {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
