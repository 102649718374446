<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :fill-color="'#000000'"
        :color="''"
        @click="$emit('click')"
    >
        <!-- rechtes Icon -->
        <path
            d="M19.499,12.454c-0.221,0-0.436-0.065-0.621-0.188c-0.126-0.084-0.233-0.19-0.316-0.317C17.232,9.946,15,6.313,15,4.5
		C15,2.019,17.019,0,19.5,0S24,2.019,24,4.5c0,1.806-2.232,5.443-3.562,7.45c-0.166,0.251-0.42,0.421-0.714,0.481
		C19.649,12.446,19.574,12.454,19.499,12.454z M19.5,1.5c-1.654,0-3,1.346-3,3c0,0.938,1.167,3.32,3,6.145
		c1.833-2.829,3-5.211,3-6.145C22.5,2.846,21.154,1.5,19.5,1.5z"
            :fill="colorDestinationIcon"
        />
        <path
            d="M19.5,5.625c-0.044,0-0.088-0.004-0.131-0.011c-0.513-0.06-0.922-0.47-0.982-0.983c-0.008-0.042-0.011-0.086-0.011-0.13
		s0.004-0.088,0.011-0.13c0.06-0.513,0.47-0.923,0.982-0.983c0.043-0.008,0.087-0.011,0.131-0.011s0.088,0.004,0.131,0.011
		c0.513,0.06,0.922,0.47,0.983,0.983c0.007,0.042,0.011,0.086,0.011,0.13s-0.004,0.088-0.011,0.13
		c-0.061,0.513-0.47,0.923-0.983,0.983C19.588,5.621,19.544,5.625,19.5,5.625z"
            :fill="colorDestinationIcon"
        />

        <!-- linkes icon -->
        <path
            d="M5.25,13.875c-0.044,0-0.088-0.004-0.13-0.011c-0.513-0.06-0.923-0.47-0.983-0.982c-0.008-0.043-0.011-0.087-0.011-0.131
		s0.004-0.088,0.011-0.131c0.06-0.513,0.47-0.922,0.982-0.982c0.043-0.008,0.087-0.012,0.131-0.012s0.088,0.004,0.131,0.012
		c0.513,0.061,0.922,0.47,0.982,0.982c0.008,0.043,0.011,0.087,0.011,0.131s-0.004,0.088-0.011,0.131
		c-0.06,0.513-0.47,0.922-0.983,0.982C5.338,13.871,5.294,13.875,5.25,13.875z"
            :fill="colorStartIcon"
        />
        <path
            d="M5.249,20.926c-0.337,0-0.656-0.11-0.922-0.317c-0.099-0.078-0.185-0.165-0.26-0.26C2.212,17.977,0,14.733,0,12.75
		C0,9.855,2.355,7.5,5.25,7.5s5.25,2.355,5.25,5.25c0,1.98-2.212,5.226-4.068,7.6C6.146,20.716,5.715,20.926,5.249,20.926z M5.25,9
		C3.182,9,1.5,10.682,1.5,12.75c0,1.161,1.437,3.719,3.75,6.676C7.563,16.467,9,13.909,9,12.75C9,10.682,7.318,9,5.25,9z"
            :fill="colorStartIcon"
        />

        <!-- "Weg" -->
        <path
            d="M5.25,24c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h9c0.414,0,0.75-0.336,0.75-0.75v-4.5
		c0-1.241,1.009-2.25,2.25-2.25h3c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75h-3c-0.414,0-0.75,0.336-0.75,0.75v4.5
		c0,1.241-1.009,2.25-2.25,2.25H5.25z"
            :fill="colorWayIcon"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'TripDestinationIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        colorDestinationIcon: {
            type: String,
            default: '#000000',
        },
        colorStartIcon: {
            type: String,
            default: '#000000',
        },
        colorWayIcon: {
            type: String,
            default: '#000000',
        },
    },
}
</script>

<style lang="scss" scoped></style>
