<template>
    <list-item
        :class="{
            historyListItem: true,
            'location-history-list-item_selectable': isAccuratePositionMeasurement(),
            'u-bb': true,
            'location-history-list-item_selected': selected,
        }"
        @list-item-click="
            isAccuratePositionMeasurement() ? $emit('click', location) : null
        "
    >
        <div class="l-inline l-spread l-full-width">
            <div class="l-inline l-gap-2">
                <div>
                    <div class="number-icon">
                        <span>{{ location.index }}</span>
                    </div>
                </div>
                <div class="l-stack l-gap-0">
                    <h3>
                        <time-ago :from-datetime="location.timestamp" />
                    </h3>
                    <p class="t-small">
                        {{ formatTimeWithTime(location.timestamp) }}
                    </p>

                    <p
                        v-if="!location.is_position_accurate"
                        class="l-inline l-center-v l-gap-1 t-small"
                    >
                        <PinNoLocationIcon width="20" height="16" />

                        <span class="t-subtle">
                            {{ $t('inaccuratePosition') }}
                        </span>
                    </p>

                    <p
                        v-if="
                            (location.kind === 'gps-fix' ||
                                location.kind === 'indoor') &&
                                location.gps_accuracy != null
                        "
                        class="l-inline l-center-v l-gap-1 t-small"
                    >
                        <PinLocationIcon width="20" height="16" />

                        <span class="t-subtle sensor-data-distance">
                            {{ $t('accuracy') }}:
                            {{ location.gps_accuracy }}
                        </span>
                    </p>

                    <div
                        v-if="location.sensor_data.battery != null"
                        class="l-inline l-center-v l-gap-1 t-small t-subtle"
                    >
                        <BatteryIcon
                            :battery-level-in-percent="
                                location.sensor_data.battery
                            "
                            width="20"
                            height="20"
                            :warn="false"
                        ></BatteryIcon>
                        <p>{{ location.sensor_data.battery }} %</p>
                    </div>
                </div>
            </div>
            <div class="l-inline l-spread l-gap-1">
                <signal-strength-indicator
                    v-if="
                        location.network_data &&
                            location.network_data.rssi &&
                            location.network_data.snr
                    "
                    :network-rssi="location.network_data.rssi"
                    :network-snr="location.network_data.snr"
                />
                <pin-home-icon
                    v-if="location.kind === 'indoor'"
                    width="24"
                    height="24"
                    :name="$t('HomeIcon')"
                />
                <pin-earth-icon
                    v-else-if="
                        location.kind === 'gps-fix' &&
                            location.is_position_accurate
                    "
                    width="24"
                    height="24"
                    :name="$t('EarthIcon')"
                />
                <pin-no-location-icon
                    v-else-if="location.kind === 'gps-fix'"
                    width="24"
                    height="24"
                    color="#fdae61"
                    :name="$t('inaccuratePosition')"
                />
                <pin-warning-icon
                    v-else-if="location.kind === 'gps-no-fix'"
                    width="24"
                    height="24"
                    :name="$t('WarningIcon')"
                    color="#d32f2f"
                />
                <left-zone-icon
                    v-if="location.is_outside"
                    width="24"
                    height="24"
                    :name="$t('LeftZoneIcon')"
                />
                <trip-destination-icon
                    v-if="
                        location.event === 'end-trip' ||
                            location.event === 'start-trip' ||
                            location.event === 'in-trip'
                    "
                    width="24"
                    height="24"
                    :color-destination-icon="
                        location.event == 'end-trip' ? '#fdae61' : '#000000'
                    "
                    :color-start-icon="
                        location.event == 'start-trip' ? '#41b883' : '#000000'
                    "
                    :color-way-icon="
                        location.event == 'in-trip' ? '#41b883' : '#000000'
                    "
                    :name="$t(location.event)"
                />
                <stopwatch-icon
                    v-else-if="location.event == 'periodic'"
                    width="24"
                    height="24"
                    :name="$t(location.event)"
                />
                <refresh-arrows-icon
                    v-else-if="location.event == 'reboot'"
                    width="24"
                    height="24"
                    :name="$t(location.event)"
                />
                <cog-play-icon
                    v-else-if="location.event == 'manual-trigger'"
                    width="24"
                    height="24"
                    :name="$t(location.event)"
                />
                <data-file-add-icon
                    v-else-if="location.event == 'data-change'"
                    width="24"
                    height="24"
                    :name="$t(location.event)"
                />
            </div>
        </div>
    </list-item>
</template>

<script>
import ListItem from '@/components/ListItem'
import SignalStrengthIndicator from './SignalStrengthIndicator'
import BatteryIcon from '@/components/icons/BatteryIcon'
import TimeAgo from '@/components/TimeAgo'
import LeftZoneIcon from '@/components/icons/CursorMoveTargetRightIcon'
import PinHomeIcon from './icons/PinHomeIcon'
import PinWarningIcon from './icons/PinWarningIcon'
import PinEarthIcon from './icons/PinEarthIcon'
import PinLocationIcon from './icons/PinLocationIcon'
import TripDestinationIcon from './icons/TripDestinationIcon'
import StopwatchIcon from './icons/StopwatchIcon'
import RefreshArrowsIcon from './icons/RefreshArrowsIcon'
import CogPlayIcon from './icons/CogPlayIcon'
import DataFileAddIcon from './icons/DataFileAddIcon'
import moment from 'moment-timezone'
import PinNoLocationIcon from '@/components/icons/PinNoLocationIcon'

export default {
    components: {
        PinNoLocationIcon,
        ListItem,
        BatteryIcon,
        TimeAgo,
        LeftZoneIcon,
        PinHomeIcon,
        PinWarningIcon,
        PinEarthIcon,
        PinLocationIcon,
        TripDestinationIcon,
        StopwatchIcon,
        RefreshArrowsIcon,
        CogPlayIcon,
        DataFileAddIcon,
        SignalStrengthIndicator,
    },
    props: {
        location: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        formatTimeWithTime(time) {
            return moment(time).format('DD.MM.YYYY HH:mm:ss')
        },
        isAccuratePositionMeasurement() {
            return (
                (this.location.kind === 'gps-fix' ||
                    this.location.kind === 'indoor') &&
                this.location.is_position_accurate
            )
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "HomeIcon": "Indoor position",
            "WarningIcon": "No GPS reception",
            "inaccuratePosition": "Inaccurate position",
            "EarthIcon": "GPS position",
            "LeftZoneIcon": "Left zone",
            "accuracy": "GPS accuracy",
            "start-trip": "start",
            "end-trip": "end",
            "in-trip": "en route",
            "periodic": "periodic",
            "reboot": "reboot",
            "manual-trigger": "manual trigger",
            "data-change": "data changed"
        },
        "de": {
            "HomeIcon": "Position im Innenbereich",
            "WarningIcon": "Kein GPS-Empfang",
            "inaccuratePosition": "Ungenaue Position",
            "EarthIcon": "GPS Position",
            "LeftZoneIcon": "Zone verlassen",
            "accuracy": "GPS Genauigkeit",
            "start-trip": "Start",
            "end-trip": "Ende",
            "in-trip": "Unterwegs",
            "periodic": "Periodisch",
            "reboot": "Neustart",
            "manual-trigger": "Manuelle Auslösung",
            "data-change": "Daten Änderung"

        },
        "it": {
            "HomeIcon": "Posizione interna",
            "WarningIcon": "Nessuna ricezione GPS",
            "EarthIcon": "Posizione GPS",
            "inaccuratePosition": "Posizione imprecisa",
            "LeftZoneIcon": "Escono Zona",
            "accuracy": "GPS precisione",
            "start-trip": "inizio",
            "end-trip": "fine",
            "in-trip": "sotto forma di",
            "periodic": "periodico",
            "reboot": "riavvio",
            "manual-trigger": "grilletto manuale",
            "data-change": "dati modificati"
        }
    }
</i18n>

<style lang="scss" scoped>
.location-history-list-item_selected.location-history-list-item_selected {
    background: $color-gray-light;
}

.location-history-list-item_selectable {
    cursor: pointer;

    .number-icon {
        background-color: black;
    }
}

.number-icon {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-gray-darker;
    color: white;
}

.number-icon span {
    text-align: center;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 0.8em;
}

.location-history-list-item_selected .number-icon {
    background-color: rgb(239, 38, 85);
}
</style>
