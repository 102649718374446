<template>
    <div class="l-stack">
        <div class="l-stack u-bb">
            <hidden-form
                class="u-bb"
                :hidden="customFormHidden"
                @toggle="customFormHidden = $event"
            >
                <template #title>
                    {{ $t('customFormTitle') }}
                </template>

                <template #form>
                    <div class="l-stack l-gap-1 l-padded">
                        <date-range-input
                            v-model="customRange"
                            @input="loadAssetHistoryCustom"
                        />
                    </div>
                </template>
            </hidden-form>

            <hidden-form
                :hidden="predefinedFormHidden"
                @toggle="predefinedFormHidden = $event"
            >
                <template #title>
                    {{ $t('predefinedFormTitle') }}
                </template>

                <template #form>
                    <div class="l-stack l-gap-1 l-padded">
                        <ax-button
                            v-for="(choice, key, index) in dateChoices"
                            :key="index"
                            @click="loadAssetHistoryFromDateRange(choice)"
                        >
                            {{ $t(key) }}
                        </ax-button>
                    </div>
                </template>
            </hidden-form>
        </div>

        <div class="history-list l-end">
            <div v-if="loadMorePossible" class="l-stack l-gap-1 l-padded">
                <ax-button @click="loadMore()">{{ $t('loadMore') }}</ax-button>
            </div>

            <div v-if="isLoadingMore" class="l-stack l-center l-padded">
                <Spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <List :is-loading="isLoading">
                <asset-location-history-item
                    v-for="location in assetHistoryData"
                    :key="location.id"
                    :location="location"
                    :selected="selectedHistoryLocationID == location.id"
                    :class="'historyItem-' + location.id"
                    @click="handleListItemClick"
                />
            </List>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Spinner from 'vue-simple-spinner'
import moment from 'moment-timezone'

import domHelper from '@/utils/modules/dom.helper'
import httpHelper from '@/utils/modules/http.helper'

import AssetLocationHistoryItem from '@/components/AssetLocationHistoryItem'
import AxButton from '@/components/AxButton'
import DateRangeInput from '@/components/DateRangeInput'
import HiddenForm from '@/components/HiddenForm'
import List from '@/components/List'

export default {
    name: 'AssetLocationHistory',
    components: {
        Spinner,
        AssetLocationHistoryItem,
        AxButton,
        DateRangeInput,
        HiddenForm,
        List,
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            dateChoices: {
                today: {
                    startDate: moment().startOf('day'),
                    endDate: moment().endOf('day'),
                },
                yesterday: {
                    startDate: moment()
                        .subtract(1, 'days')
                        .startOf('day'),
                    endDate: moment()
                        .subtract(1, 'days')
                        .endOf('day'),
                },
                thisMonth: {
                    startDate: moment().startOf('month'),
                    endDate: moment().endOf('month'),
                },
                lastMonth: {
                    startDate: moment()
                        .subtract(1, 'month')
                        .startOf('month'),
                    endDate: moment()
                        .subtract(1, 'month')
                        .endOf('month'),
                },
                thisYear: {
                    startDate: moment().startOf('year'),
                    endDate: moment().endOf('year'),
                },
                lastYear: {
                    startDate: moment()
                        .subtract(1, 'year')
                        .startOf('year'),
                    endDate: moment()
                        .subtract(1, 'year')
                        .endOf('year'),
                },
                lastFiveyears: {
                    startDate: moment()
                        .subtract(5, 'year')
                        .startOf('year'),
                    endDate: moment().endOf('day'),
                },
            },

            startDate: moment().startOf('day'),
            endDate: moment().endOf('day'),
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            offset: 0,
            limit: 1000,
            isLoading: false,
            isLoadingMore: false,
            predefinedFormHidden: true,
            customFormHidden: true,
        }
    },
    computed: {
        ...mapGetters('tracker', [
            'assetHistoryData',
            'selectedHistoryLocationID',
        ]),
        assetHistoryLocations() {
            if (this.assetHistoryData) {
                return this.assetHistoryData
            }

            return null
        },
        loadMorePossible() {
            if (this.isLoading || this.isLoadingMore) {
                return false
            }

            if (this.assetHistoryLocations?.length) {
                return (
                    this.assetHistoryLocations[
                        this.assetHistoryLocations.length - 1
                    ].index > 1
                )
            }

            return false
        },
    },
    watch: {
        selectedHistoryLocationID(selectedHistoryLocationID) {
            const [historyItemElement] = this.$el.getElementsByClassName(
                'historyItem-' + selectedHistoryLocationID
            )

            if (
                historyItemElement &&
                !domHelper.isElementVisible(historyItemElement)
            ) {
                historyItemElement.scrollIntoView()
            }
        },
        customDateRange(value) {
            this.loadAssetHistoryFromDateRange({
                startDate: moment(value.startDate).startOf('day'),
                endDate: moment(value.endDate).endOf('day'),
            })
        },
    },
    mounted() {
        this.predefinedFormHidden = !this.assetHistoryData
        this.customFormHidden = !!this.assetHistoryData
    },
    methods: {
        ...mapMutations('tracker', [
            'addHistoryData',
            'setSelectedHistoryLocationID',
            'resetHistoryData',
        ]),
        async loadAssetHistoryFromDateRange(dates) {
            this.resetHistoryData()
            this.startDate = dates.startDate
            this.endDate = dates.endDate
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            this.offset = 0

            this.isLoading = true
            await this.loadAssetHistory(this.offset, this.limit)
            this.isLoading = false
        },
        loadAssetHistoryCustom(dates) {
            this.loadAssetHistoryFromDateRange({
                startDate: moment(dates.startDate),
                endDate: moment(dates.endDate),
            })
        },
        async loadMore() {
            this.offset += this.limit
            this.isLoadingMore = true
            await this.loadAssetHistory(this.offset, this.limit)
            this.isLoadingMore = false
        },
        async loadAssetHistory(offset, limit) {
            // const history = []
            // let url = [
            //     'measurements/?',
            //     'timestamp_min=' + encodeURIComponent(this.startDate.format()),
            //     '&timestamp_max=' + encodeURIComponent(this.endDate.format()),
            //     '&tracker=' + this.id,
            //     '&limit=1000',
            // ].join('')

            // while (url) {
            //     const { data } = await httpHelper.get(url)
            //     history.push(...data.results)
            //     url = data.next
            // }

            // this.addHistoryData(
            //     history.map((item, i) => {
            //         item.index = history.length - i
            //         return item
            //     })
            // )

            const url = [
                'measurements/?',
                'timestamp_min=' + encodeURIComponent(this.startDate.format()),
                '&timestamp_max=' + encodeURIComponent(this.endDate.format()),
                '&tracker=' + this.id,
                '&offset=' + offset,
                '&limit=' + limit,
            ].join('')

            const { data } = await httpHelper.get(url)
            this.addHistoryDataFromResponse(data, this.offset)
        },
        addHistoryDataFromResponse(data, offset) {
            let locationsWithIndex = data.results.map((location, index) => {
                location.index = data.count - offset - index
                return location
            })

            this.addHistoryData(locationsWithIndex)
        },
        handleListItemClick(historyLocation) {
            this.setSelectedHistoryLocationID(historyLocation.id)
        },
    },
    beforeRouteLeave(to, from, next) {
        this.resetHistoryData()
        next()
    },
}
</script>

<i18n>
{
    "en": {
        "predefinedFormTitle": "Predefined date range",
        "customFormTitle": "Custom date range",
        "today": "Today",
        "yesterday": "Yesterday",
        "thisMonth": "This month",
        "lastMonth": "Last month",
        "thisYear": "This year",
        "lastYear": "Last year",
        "lastFiveyears": "Last 5 years",
        "loadMore": "Load more"
    },
    "de": {
        "predefinedFormTitle": "Vordefinierter Zeitraum",
        "customFormTitle": "Benutzerdefinierter Zeitraum",
        "today": "Heute",
        "yesterday": "Gestern",
        "thisMonth": "Diesen Monat",
        "lastMonth": "Letzer Monat",
        "thisYear": "Dieses Jahr",
        "lastYear": "Letztes Jahr",
        "lastFiveyears": "Letzte 5 Jahre",
        "loadMore": "Mehr laden"
    },
    "it": {
        "predefinedFormTitle": "Intervallo di date predefinito",
        "customFormTitle": "Intervallo di date personalizzato",
        "today": "Oggi",
        "yesterday": "Ieri",
        "thisMonth": "Queso Mese",
        "lastMonth": "Lo scorso Mese",
        "thisYear": "Quest' anno",
        "lastYear": "L'anno scorso",
        "lastFiveyears": "Ultimi 5 Anni",
        "loadMore": "Più"
    }
}
</i18n>

<style lang="scss" scoped>
.history-items-count {
    text-align: right;
    justify-content: right;
}

.location-history-list-item_selected.location-history-list-item_selected {
    background: $color-gray-light;
}
</style>
